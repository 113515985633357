


































import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {Getter} from 'vuex-class';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {profileStore} from '@/store/modules/profile';
import ToolTip from '@/components/custom/ToolTip.vue';
import {tourStore} from '@/store/modules/tour';
import {RolesList} from '@/domain/model/types';

@Component({
  name: 'contact-list-item',
  components: {GroupAvatar, AvatarWithStatus, ToolTip}
})
export default class ContactListItem extends Vue {
  @Prop() item?: any;
  @Prop({default: true}) border?: boolean;
  @Prop({default: false}) rounded?: boolean;
  @Prop({default: true}) listItem?: boolean;
  @Prop({default: false}) editMode?: boolean;
  @Prop({default: 44}) avatarSize?: number | string;
  @Prop({default: -1}) index?: number;
  @Prop({default: ''}) groupTitle?: string;

  @Getter selectedContact;

  get t2bUser() {
    return profileStore.t2bUser
  }

  // todo: check this (refactoring)
  get itemTour() {
    const isTour4 = tourStore.checkTour({tourNumber: 4, role: RolesList.admin})
    if (isTour4 && this.item.id === tourStore.tourAssociate?.id) {
      return 'selected'
    } else {
      return false
    }
  }

  get invisible() {
    return !this.item?.rules?.VISIBILITY?.visible
  }

  get sizes() {
    return {
      'x-small': 44,
      'small': 48,
      'medium': 56
    };
  }

  get useToolTip(): boolean {
    if (!this.border && this.title.length > 18) {
      return true
    }
    return false
  }

  get group(): boolean {
    return this.item.type === 2;
  }

  get selected(): boolean {
    return this.selectedContact?.id === this.item.id;
  }

  get title(): string {
    return this.item?.name;
  }

  get subtitle(): string {
    return this.item.position || this.item.description;
  }

  get photoUrl(): string {
    return this.item?.photoUrl?.normal
  }

  get online(): boolean {
    if (this.item?.associate) {
      return !!this.item?.associate?.status?.online;
    }
    if (this.item?.contacts) {
      return !!this.item.contacts.find(item => !!item.associate?.status?.online);
    }
    return false;
  }

  get marginRight(): string {
    if (!this.border) {
      return 'mr-2'
    }
    return 'mr-4'
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  onItemSelected() {
    if (!this.selected) {
      this.$emit('contact-clicked', this.item);
    }
  }
};
