import {Component, Vue} from 'vue-property-decorator';
import Notifications from '@/components/mixins/Notifications';

@Component({
  name: 'container-calc'
})
export default class ContainerCalc extends Notifications {
  protected calcHeight(container: any | [], classList: any | [] = [], style: any | string = '') {
    if (!container) {
      return
    }
    const containerArr: any[] = (Array.isArray(container) ? container : [container]);
    this.$nextTick(() => {
      containerArr.forEach(element => {
        const containerElement = (element.hasOwnProperty('$el') ? element.$el : element) as HTMLElement;
        if (typeof containerElement.getBoundingClientRect === 'function') {
          try {
            const rect = containerElement.getBoundingClientRect();
            containerElement.setAttribute('style', `height: calc(100vh - ${rect.y}px); ${style}`);
            containerElement.classList.add('overflow-y-auto');
            classList.forEach(item => containerElement.classList.add(item))
          } catch (err) {
            console.error(err, 'ContainerCalc -> containerElement.getBoundingClientRect()')
          }
        }
      })
    })
  }
}
