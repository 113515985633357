





































































































































































































import Component, {mixins} from 'vue-class-component';
import {Action, Getter} from 'vuex-class';
import {BusinessContact, BusinessDepartment} from '@/domain/model/types';
import {Prop, Watch} from 'vue-property-decorator';
import constants from '@/common/constants';
import {firestore} from '@/plugins/firebase.init';
import {findLastFlatIndex, flattenDirectory} from '@/utils/helpers';
import BaseDialog from '@/components/BaseDialog.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {applicationStore} from '@/store/modules/application';
import VcSwitch from '@/components/VCSwitch.vue';
import SearchView from '@/components/SearchView.vue';
import rfdc from 'rfdc';
import Notifications from '@/components/mixins/Notifications';
import {doc, writeBatch} from 'firebase/firestore';
import {businessDirectory, businessDirectoryContact} from '@/data/firebase';

const clone = rfdc({proto: true})

@Component({
  name: 'dialog-create-edit-department',
  components: {SearchView, VcSwitch, AvatarWithStatus, BaseDialog}
})
export default class DialogCreateEditDepartment extends mixins(Notifications) {
  @Prop() value!: boolean;

  @Prop({
    required: false,
    default: function() {
      return {group: {name: 'shared', pull: 'clone', put: false, revertClone: true}, sort: false};
    }
  })
  options?: any | null;

  saving: boolean = false;
  valid: boolean = true;
  includeContacts: boolean = false;

  // Form data
  department: any = {};
  departmentName: string = '';
  departmentParentName: string = '';
  depPathString: string = '';
  departmentSubParentName?: string | null = null;
  departmentContacts: BusinessContact[] = [];
  departmentPath: string[] = [];
  departmentVisibility: boolean = false;

  expanded: boolean = false;

  stepDepartmentInfo: string = 'dep-info'
  stepAddContacts: string = 'add-contacts'
  stepViewContacts: string = 'view-contacts'
  currentStep: string = this.stepDepartmentInfo

  customContacts: { id: string, data: any, selected: boolean }[] = []
  customContactsOriginal: { id: string, data: any, selected: boolean }[] = []

  editedDepartment: BusinessDepartment | null = null
  directoryContacts: BusinessContact[] = [];
  @Getter businessDirectory!: BusinessContact[];
  @Getter selectedDepartment?: BusinessDepartment | null;
  @Getter directoryInEditMode;

  get title() {
    switch (this.currentStep) {
      case this.stepDepartmentInfo:
        return this.directoryInEditMode ? 'Edit Department' : 'Add Department to Directory';
      case this.stepAddContacts:
        return 'Add Contacts to Department';
      case this.stepViewContacts:
        return 'View Contacts';
      default:
        return '';
    }
  }

  get subtitle() {
    switch (this.currentStep) {
      case this.stepDepartmentInfo:
        return !this.directoryInEditMode ? 'Complete this form to create a new department' : '';
      case this.stepAddContacts:
        return 'Choose specific contacts for this department';
      default:
        return '';
    }
  }

  get business() {
    return applicationStore.business;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
    if (!value) {
      this.closeCreateEditDepartment()
    }
  }

  get editMode() {
    return this.selectedDepartment;
  }

  get categories() {
    return this.businessDirectory.filter(value => value.type === constants.TYPE_CONTACT_NODE && !value.path);
  }

  get departments() {
    let rootDep: BusinessContact[] = this.businessDirectory
    const length = !this.editedDepartment ? this.departmentPath.length - 1 : this.departmentPath.length
    for (let i = 0; i < length; i++) {
      const depName = this.departmentPath[i];
      const foundDep = rootDep.find((item) => item.name === depName && item.type === constants.TYPE_CONTACT_NODE);
      if (!foundDep && i === 0) {
        return []
      }
      if (foundDep?.contacts?.length) {
        rootDep = foundDep.contacts
      } else {
        return []
      }
    }
    return rootDep?.filter((item) => item.type === constants.TYPE_CONTACT_NODE) || [];
  }

  get searchItems() {
    const depName = this.departmentName.toLowerCase();
    return this.departments.filter((item) => {
      const name = item.name.toLowerCase();
      return !this.departmentName || name.includes(depName)
    });
  }

  get departmentNameExists() {
    return !!this.searchItems.find((item) => item.name.toLowerCase() === this.departmentName.toLowerCase())
  }

  get subCategories() {
    if (!this.departmentParentName) {
      return [];
    }
    const categories = this.directoryContacts.find(value => value.name === this.departmentParentName);
    return categories ? (categories.contacts ? categories.contacts.filter(value => value.type === constants.TYPE_CONTACT_NODE) : []) : [];
  }

  get subCategories2() {
    if (!this.departmentParentName) {
      return [];
    }
    const callback = (value) => value.type === constants.TYPE_CONTACT_NODE && (!!value.path && value.path.includes(this.departmentParentName!));
    return this.directoryContacts.filter(callback);
  }

  get form(): any {
    return this.$refs.formAddDep;
  }

  get includedContacts() {
    return this.customContacts.reduce((count, item) => {
      return item.selected ? ++count : count;
    }, 0);
  }

  get selectedContacts() {
    return this.customContacts.filter(item => item.selected)
  }

  get depNameChanged() {
    return this.departmentName !== this.editedDepartment?.name
  }

  get depContactsChanged() {
    const srcLength = this.departmentContacts?.length || 0;
    const lengthChanged = this.customContactsOriginal?.length !== srcLength
        || this.customContactsOriginal?.length != this.includedContacts;
    let contactChanged = false
    if (!lengthChanged) {
      contactChanged = this.customContactsOriginal?.filter((item) =>
          !!this.departmentContacts?.find((contact) => item.data.name === contact.name))?.length !== srcLength
    }
    console.log(`depContactsChanged: lengthChanged=${lengthChanged}, contactChanged=${contactChanged}`)
    return lengthChanged || contactChanged
  }

  @Action closeCreateEditDepartment;
  @Action onEditDepartment

  @Watch('depPathString')
  onDepartmentNameChanged(after: string, before: string) {
    this.departmentPath = after.split('/');
    this.departmentName = this.departmentPath.length === 1 ? this.departmentPath[0] : this.departmentPath[this.departmentPath.length - 1];
    if (this.departmentPath.length > 1) {
      this.departmentParentName = this.departmentPath[this.departmentPath.length - 2];
      return;
    }
    this.departmentParentName = '';
  }

  @Watch('includeContacts')
  onIncludeContactsChanged(newValue: boolean, oldValue: boolean) {
    if (!newValue) {
      this.departmentContacts = [];
    }
  }

  onContactSelected(contact) {
    const indexOf1 = this.customContacts.indexOf(contact);
    const indexOf2 = this.customContactsOriginal.indexOf(contact);
    const item = {...contact, selected: !contact.selected};
    this.customContacts.splice(indexOf1, 1, item);
    this.customContactsOriginal.splice(indexOf2, 1, item);
  }

  searchContacts(term) {
    if (term === null || term === '') {
      this.customContacts = this.customContactsOriginal;
      return;
    }
    const searchString = term.toLowerCase();
    this.customContacts = this.customContactsOriginal.filter((item) => {
      return item.data.name.toLowerCase().startsWith(searchString);
    });
  }

  onDepSelected(department: string) {
    this.depPathString = this.depPathString?.replace(this.departmentName, '');
    if (!this.depPathString) {
      this.depPathString = `${department}/`;
      return;
    }
    this.depPathString = `${this.depPathString}${department}/`;
  }

  photoUrl(item) {
    return item.photoUrl?.normal;
  }

  isOnline(contact) {
    const item = contact.associate || contact;
    const status = item.status;
    return status && status.online;
  }

  async saveDepartment() {
    if (this.form.validate()) {
      if (this.includeContacts && !this.includedContacts) {
        alert('Department is empty. Add contacts!');
        return;
      }
      try {
        await this.createDirectoryDepartment2();
      } catch (e: any) {
        alert(e.message);
        return;
      }
      this.show = false;
    }
  }

  async saveChanges2() {
    if (this.form.validate()) {
      this.editedDepartment!.rules!.VISIBILITY!.visible = this.departmentVisibility
      this.editedDepartment!.expanded = this.expanded
      this.editedDepartment!.name = this.departmentName
      this.editedDepartment!.path = this.departmentPath;
      const selectedContacts = this.customContactsOriginal.filter((item) => item.selected).map((item) => item.data)
      this.departmentContacts.forEach((contact, index) => {
        if (contact.type === 3) {
          selectedContacts.splice(index, 0, contact)
        }
      })
      this.editedDepartment!.contacts = this.includeContacts ? selectedContacts : [];
      try {
        this.onEditDepartment(this.editedDepartment)
      } catch (e: any) {
        alert(e.message);
        return;
      }
      this.show = false;
    }
  }

  async createDirectoryDepartment2() {
    const departmentData: BusinessDepartment = {
      type: constants.TYPE_CONTACT_NODE,
      name: '',
      business: {id: this.business!.id!, name: this.business!.name},
      rules: {
        VISIBILITY: {
          visible: this.departmentVisibility
        }
      },
      expanded: this.expanded,
      flatIndex: -1,
      contacts: this.departmentContacts
    };

    const paths = this.directoryContacts.map((item) => {
      return {
        index: item.flatIndex,
        path: !!item.path?.length ? item.path.join('/') + '/' + item.name : item.name
      };
    });

    const newPath: string[] = [];
    let {flatIndex, parentPath} = findLastFlatIndex(paths.reverse(), newPath, this.departmentPath);
    let lastParentIndex = flatIndex;
    newPath.forEach((dep, index) => {
      const depData: BusinessDepartment = clone(departmentData);
      depData.name = dep
      depData.path = [...parentPath]
      parentPath = [...depData.path, depData.name];
      lastParentIndex = flatIndex + index + 1;
      this.directoryContacts.splice(lastParentIndex, 0, depData);
    });

    if (this.includeContacts) {
      this.departmentContacts = this.customContactsOriginal.filter((item) => item.selected).map((item) => item.data);
      this.departmentContacts.forEach((contact, index) => {
        const detachedContact = this.directoryContacts.splice(this.directoryContacts.indexOf(contact), 1)[0];
        this.directoryContacts.splice(lastParentIndex + index + 1, 0, {
          ...detachedContact,
          path: parentPath,
          flatIndex: -1
        });
      });
    }

    // recalculate indexes
    const updatedDeps = this.directoryContacts.filter((value, index) => {
      const altered = value.flatIndex !== index;
      if (altered) {
        value.flatIndex = index;
      }
      return altered;
    });

    try {
      const batch = writeBatch(firestore);
      for (const item of updatedDeps) {
        if (!item.id) {
          batch.set(doc(businessDirectory(this.business!.id!)), item);
        } else {
          batch.update(businessDirectoryContact(this.business!.id!, item.id!),
              'flatIndex', item.flatIndex,
              'path', item.path || []);
        }
      }
      await batch.commit();
      this.showInfo('Department created')
      this.show = false;
    } catch (error) {
      console.log(error)
      this.showIssue('Failed create department');
    }
  }

  async updateDirectoryDepartment() {
    const departmentData: BusinessDepartment | null | undefined = this.selectedDepartment;
    if (!departmentData) {
      return
    }

    departmentData.name = this.departmentName

    if (this.departmentContacts.length) {
      departmentData.contacts = this.departmentContacts;
    }

    const paths = this.directoryContacts.map((item) => {
      return {
        name: item.name,
        index: item.flatIndex,
        path: item.path ? item.path.join('/') + `/${item.name}` : item.name
      };
    });

    const newPath: string[] = [];
    let {flatIndex, parentPath} = findLastFlatIndex(paths.reverse(), newPath, this.departmentPath);

    // let lastParentPath = this.departmentPath;
    let lastParentIndex = flatIndex;
    newPath.forEach((dep, index) => {
      const depData = Object.assign({}, departmentData, {
        name: dep,
        path: [...parentPath]
      });
      parentPath = [...depData.path, depData.name];
      lastParentIndex = flatIndex + index + 1;
      this.directoryContacts.splice(lastParentIndex, 0, depData);
    });

    if (this.includeContacts) {
      this.departmentContacts = this.customContactsOriginal
          .filter((item) => item.selected)
          .map((item) => item.data);
      this.departmentContacts.forEach((contact, index) => {
        const detachedContact = this.directoryContacts.splice(this.directoryContacts.indexOf(contact), 1)[0];
        this.directoryContacts.splice(lastParentIndex + index + 1, 0, {
          ...detachedContact,
          path: parentPath,
          flatIndex: -1
        });
      });
    }

    // recalculate indexes
    const updatedDeps = this.directoryContacts.filter((value, index) => {
      const altered = value.flatIndex !== index;
      if (altered) {
        value.flatIndex = index;
      }
      return altered;
    });

    try {
      const batch = writeBatch(firestore);
      for (const item of updatedDeps) {
        if (!item.id) {
          batch.set(doc(businessDirectory(this.business!.id!)), item);
        } else {
          batch.update(businessDirectoryContact(this.business!.id!, item.id!),
              'flatIndex', item.flatIndex,
              'path', item.path || []);
        }
      }
      await batch.commit();
      this.showInfo('Department updated');
      this.show = false;
    } catch (error) {
      console.error(error)
      this.showIssue('Failed create department');
    }
  }

  onAddContactToDepartment(/**Event*/evt) {
    // same properties as onEnd
    console.log(`Associates - onAdd: oldIndex = ${evt.oldIndex}, newIndex = ${evt.newIndex}`);
    if (!this.departmentContacts) {
      this.departmentContacts = [];
    }
    // this.departmentContacts[evt.newIndex] = Object.assign({}, this.contacts[evt.oldIndex])
  }

  created() {
    flattenDirectory(this.businessDirectory, this.directoryContacts)
    this.customContacts = this.directoryContacts.filter((item) => item.type !== 3).map((item) => {
      return {id: item.id!, data: item, selected: false};
    }).sort((a, b) => {
      if (!!a.data.type && !!b.data.type && a.data.type < b.data.type) {
        return -1
      }
      if (!!a.data.type && !!b.data.type && a.data.type > b.data.type) {
        return 1
      }
      if (a.data.name < b.data.name) {
        return -1
      }
      if (a.data.name > b.data.name) {
        return 1
      }
      return 0
    });
    this.customContactsOriginal = this.customContacts;
    if (this.selectedDepartment) {
      this.editedDepartment = clone(this.selectedDepartment)
      this.departmentVisibility = !!this.editedDepartment.rules?.VISIBILITY?.visible
      this.expanded = !!this.editedDepartment.expanded;
      this.includeContacts = !!this.editedDepartment.contacts?.length;
      this.departmentName = this.editedDepartment.name;
      this.departmentContacts = this.editedDepartment.contacts || [];
      this.departmentPath = this.editedDepartment.path || [];
      this.customContacts.forEach((contact) => contact.selected = !!this.departmentContacts.find((item) => item.id === contact.id))
      this.customContactsOriginal = this.customContacts;
    }
  }
}
