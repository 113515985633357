






























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ContactListItem from '@/components/ContactListItem.vue';
import { Action, Getter } from 'vuex-class';
import { BusinessContact, BusinessDepartment } from '@/domain/model/types';
import DialogDeleteDepartment from '@/components/directory/DialogDeleteDepartment.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import { directoryStore } from '@/store/modules/directory/directoryStore';
import { tourStore } from "@/store/modules/tour";
import { profileStore } from "@/store/modules/profile";
import draggable from 'vuedraggable'
import rfdc from 'rfdc';

const clone = rfdc({ proto: true })

@Component({
  name: 'contacts-directory',
  components: { DialogDeleteDepartment, ContactListItem, DialogConfirm, draggable }
})
export default class ContactsDirectory extends Vue {
  @Prop({ default: false }) root?: boolean;
  @Prop({ default: false }) customerView!: boolean;
  @Prop() contacts?: any[];

  dragId: string = ''
  dragOverId: string = ''

  dragImage: string = '_drag.svg'
  cursorClass: string = 'cursor-grab'

  showDeleteDepartment: boolean = false
  selectedDepartment?: BusinessDepartment
  dialogConfirm: boolean = false
  titleConfirm: string = ''
  textConfirm: string = ''
  textColorConfirm: string = ''
  confirm: string = ''
  onConfirm?: any;

  contactsClone?: any[];

  @Getter directoryInEditMode;
  @Action editDepartment;
  @Action deleteDepartment
  @Action deleteContact

  getDragImage(pic) {
    return require('../assets/' + pic)
  }

  panelState(contactId: string) {
    return directoryStore.departmentsState[contactId] || 0
  }

  togglePanelState(contactId: string) {
    directoryStore.togglePanelState(contactId)
  }

  allowDrag(contact) {
    if (this.directoryInEditMode || this.customerView || contact.type === 1 || contact?.path?.length !== 0) {
      return false
    }
    return true
  }


  dragStartListener = (draggable: any) => {
    return () => {
      console.log('dragging start');
      draggable.classList.add('dragging');
    };
  };

  dragEndListener = (draggable: any) => {
    return () => {
      console.log('dragging end');
      draggable.classList.remove('dragging');
    };
  };

  // @Watch('directoryInEditMode') onEditModeChanged(after: boolean, before: boolean) {
  //     const draggables = document.querySelectorAll('.draggable');
  //     const containers = document.querySelectorAll('.drag-container');
  //     if (after) {
  //         draggables.forEach((draggable) => {
  //             draggable.addEventListener('dragstart', () => {
  //                 draggable.classList.add('dragging');
  //                 console.log('dragging start');
  //             });
  //             draggable.addEventListener('dragend', () => {
  //                 console.log('dragging end');
  //                 draggable.classList.remove('dragging');
  //             });
  //         });
  //         containers.forEach((container) => {
  //             container.addEventListener('dragover', () => {
  //                 console.log('drag over, id=' + container.id);
  //             });
  //         });
  //     } else {
  //         draggables.forEach((draggable) => {
  //             draggable.removeEventListener('dragstart', () => {
  //                 draggable.classList.add('dragging');
  //                 console.log('dragging start');
  //             });
  //             draggable.removeEventListener('dragend', () => {
  //                 console.log('dragging end');
  //                 draggable.classList.remove('dragging');
  //             });
  //         });
  //         containers.forEach((container) => {
  //             container.removeEventListener('dragover', () => {
  //             });
  //         });
  //     }
  // }

  draggableStart(e) {
    try {
      //console.log("draggableStart", e)
      if (this.contacts) {
        this.contactsClone = clone(this.contacts)
        let oldIndex = e.oldIndex
        this.dragId = this.contacts[oldIndex].id;
        let drag_img = '_dragged.svg';

        // drag image
        let handle = document.getElementById('handle-' + this.dragId)
        if (handle) {
          handle.setAttribute('src', require('../assets/' + drag_img));
        }

        let ghost_handle = document.getElementsByClassName('v-sheet theme--light transparent chosen sortable-fallback drag')[0];
        if (ghost_handle) {
          let ghost_handle_child = ghost_handle.getElementsByTagName('img')[0]
          ghost_handle_child.src = require('../assets/' + drag_img);
        }
      }
    } catch (err) {
      console.log(`draggableStart. error: ${err}`);
    }
  }

  async draggableEnd(e) {
    try {
      //console.log("draggableEnd", e);
      let newIndex = e.newIndex;
      let handle = document.getElementById('handle-' + this.dragId);
      let drag_img = '_drag.svg';

      if (handle) {
        handle.setAttribute('src', require('../assets/' + drag_img));
      }

      if (this.contactsClone) {
        let dropId = this.contactsClone[newIndex].id;
        await directoryStore.dragDirectory({
          dragId: this.dragId,
          dropId: dropId
        });
      }
    } catch (err) {
      console.log(`draggableEnd. error: ${err}`);
    }
  }

  checkMove(e) {
    //console.log("dragging", e);
    return this.isDraggable(e.draggedContext);
  }

  isDraggable(context) {
    const { index, futureIndex } = context

    if (this.contacts) {
      let contact = context.element
      let contact_future = this.contacts[futureIndex]
      if (!this.directoryInEditMode && (contact.type === 3 && (contact?.path?.length === 0)) && (contact_future.type === 3 && (contact_future?.path?.length === 0))) {
        return true
      }
    }
    return false
  }

  saveUpdatedOrder(e) {
    console.log(this.contacts);
  }

  onDragStart(id, event) {
    //console.log('onDragStart => id=' + id + '\nevent=' + JSON.stringify(event));
    this.dragOverId = '';
    this.dragId = id;

    this.cursorClass = 'cursor-grab';
    this.dragImage = '_drag.svg'
  }

  onDragOver(id, event) {
    /* console.log('onDragOver => id=' + id + '\nevent=' + JSON.stringify(event));
     this.dragOverId = '';'*/
  }

  async onDrop(id, event) {
    /*console.log('onDrop => id=' + id + '\nevent=' + JSON.stringify(event)); 

    await directoryStore.dragDirectory({
      dragId: this.dragId,
      dropId: id
    });

    this.dragOverId = '';
    this.cursorClass = 'cursor-grab'
    this.dragImage = '_drag.svg'*/
  }

  directoryContacts(contacts: any[]) {
    return this.customerView
      ? contacts.filter((item) => !!item.rules.VISIBILITY.visible)
      : contacts
  }

  borderLeftStyle(color) {
    return {
      borderLeft: `4px solid ${color || '#3773F5'}`
    };
  }

  invisible(contact: BusinessContact) {
    return contact?.rules && !contact.rules.VISIBILITY?.visible;
  }

  active(contacts): number {
    if (!contacts) {
      return 0;
    }
    return contacts.filter(item => {
      if (item.associate) {
        return !!item.associate.status?.online;
      }
      if (item.contacts) {
        return !!item.contacts.find(item => !!item.associate?.status?.online);
      }
      if (item.type === 3) {
        return this.active(item.contacts);
      }
      return false;
    }).length;
  }

  confirmDeleteDepartment(department: BusinessDepartment) {
    this.selectedDepartment = department
    this.showDeleteDepartment = true
  }

  onDeleteDepartment({ deleteAll, department }) {
    if (!department) {
      return;
    }
    this.deleteDepartment({ deleteAll, department })
  }

  onSelectContact(item: BusinessContact) {
    this.$router.push({ name: 'contact-info', params: { contactId: item.id! } });
  }

  onDeleteConfirm(contact) {
    this.titleConfirm = `Do you want to delete contact ‘${contact.name}’ ?`
    this.textConfirm = `<div class="mx-5">Warning: The contact will be removed from directory.<br>` +
      `This action can’t be undone.</div>`
    this.confirm = 'Yes, delete'
    this.textColorConfirm = 'text-secondary'
    this.onConfirm = async () => {
      this.dialogConfirm = false
      this.deleteContact(contact)
    };
    this.dialogConfirm = true
  }

  created() {
    // if (tourStore.tourNumber === 6 && profileStore.isAdmin && this.root) {
    //   tourStore.setTourSelectedObj(null)
    // }
  }

  mounted() {
    // if (tourStore.tourNumber === 6 && profileStore.isAdmin) {
    //   if (tourStore.tourSelectedObj === null) {
    //     if (typeof Object.keys(this.$refs)[0] !== 'undefined') {
    //       const htmlElement: HTMLElement = this.$refs[Object.keys(this.$refs)[0]][0]['$el'] as HTMLElement
    //       tourStore.setTourSelectedObj(htmlElement.getBoundingClientRect())
    //     }
    //   }
    // }
  }
}
