










































































import {Component, Prop} from 'vue-property-decorator';
import ContactsDirectory from '@/components/ContactsDirectory.vue';
import {Action, Getter, Mutation} from 'vuex-class';
import DialogCreateEditDepartment from '@/components/DialogCreateEditDepartment.vue';
import DialogInfoCenter from '@/components/DialogInfoCenter.vue'
import DialogSetupStatus from '@/components/DialogSetupStatus.vue';
import DialogVideo from '@/components/DialogVideo.vue';
import {applicationStore} from '@/store/modules/application';
import VcSwitch from '@/components/VCSwitch.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {mixins} from 'vue-class-component';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import EmptyState from "@/components/EmptyState.vue";
import {businessStore} from '@/store/modules/business/businessStore';
import {tourStore} from "@/store/modules/tour";
import {BusinessContact} from "@/domain/model/types";

@Component({
  name: 'directory-tree',
  components: {VcSwitch, DialogCreateEditDepartment, DialogInfoCenter, DialogSetupStatus, DialogVideo, ContactsDirectory, EmptyState}
})
export default class DirectoryTree extends mixins(ContainerCalc) {
  visible: boolean = false;
  customerView: boolean = false;  
  isInfoPanel: boolean = false;
  isInfoItems: boolean = false;  
  
  @Getter showCreateEditDepartment;
  @Getter directoryInEditMode;
  @Getter businessDirectory  

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get membersCount(): number {
    return directoryStore.directoryContactsCount;
  }

  get membersOnline(): number {
    return directoryStore.contactsOnline;
  }

  get isEmpty() {
    return !this.businessDirectory?.length
  }

  get needCustomContact() {
    // BAW-996 - automatically create a new contact for a new user 
    const needCustomContact = window.localStorage.getItem('needCustomContact') || ''; 
    window.localStorage.removeItem('needCustomContact');
    return (needCustomContact === '1');      
  }

  @Mutation setDirectoryEditMode;
  @Action createDepartment;
  @Action saveDirectoryChanges
  @Action discardDirectoryChanges

  active(contacts: any[]): number {
    return contacts && contacts.filter(item => {
      if (item.associate) {
        return item.associate.status && item.associate.status.online;
      }
      if (item.contacts) {
        return item.contacts.find(item => item.associate.status && item.associate.status.online) != null;
      }
      if (item.type === 3) {
        return this.active(item.contacts);
      }
      return false;
    }).length;
  }

  onReadSetup(){    
    this.isInfoPanel = true;
    this.isInfoItems = false;
  }

  onSaveChanges() {
    this.saveDirectoryChanges()
  }

  onDiscardChanges() {
    this.discardDirectoryChanges()
  }

  created() {
    console.log('DirectoryTree :: created');
  }

  async createFirstCustomContact(){
    try {      
      await businessStore.loadAssociates();
    
      const associateItem = businessStore.associates.filter((item) => {
        return item.id === businessStore?.associates[0]?.id;
      });     

      if(associateItem.length == 1){
        const associate = associateItem[0];

        const contactItem = {      
          associate: associate,        
          contactData: {
            name: associate.fullName,
            email: associate?.email,
            phoneNumber: associate?.phoneNumber,
            photoFile: associate?.photoUrl
          },
          defaultContact: false
        };          
        
        const contact: BusinessContact | null = await directoryStore.createCustomContact(contactItem);
        tourStore.setTourAssociate(contact);
        tourStore.setTourAction(false); 
      }else{
        console.log('createFirstCustomContact No data');
      }
    } catch (e: any) {
      console.log('createFirstCustomContact : ' +  e.message);
    }
  }

  async hasIsInfo() {  
    if(this.$route.params.isInfoPanel === '1'){
      if(businessStore.invited?.length == 0){
        await businessStore.loadInvited()
      }
      this.isInfoPanel = true;
    }
    if(this.$route.params.isInfoItems === '1'){
      //this.isInfoItems = true; // need to enable
    }
  }  

  async mounted() {
    this.calcHeight(this.$refs.directoryTreeContainer)
    this.hasIsInfo();

    if(this.needCustomContact){
      await this.createFirstCustomContact();
    }
  }

  beforeDestroy() {
    console.log('DirectoryTree :: beforeDestroy');
  }
}
