





































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {BusinessDepartment} from '@/domain/model/types';

@Component({
  name: 'dialog-delete-department',
  components: {BaseDialog}
})
export default class DialogDeleteDepartment extends Vue {
  @Prop() value!: boolean;
  @Prop({required: true}) department?: BusinessDepartment

  deleteAll: boolean = false

  get title() {
    return `Do you want to delete '${this.department?.name}' department?`
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  onCancel() {
    this.show = false
    this.$emit('on-cancel')
  }

  async onDelete() {
    this.show = false
    const {deleteAll, department} = this
    this.$emit('on-delete', {deleteAll, department})
  }
}
